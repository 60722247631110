import SelectCar from "../images/prod/01.jpg";
import Contact from "../images/prod/02.jpg";
import Drive from "../images/prod/03.jpg";

function Prod() {
  return (
    <>
      <section className="prod-section" id="prod-section">
        <div className="container">
          <div className="prod-container">
            <div className="prod-container__title">
              <h3>Products</h3>
              {/* <h2>ARASA Trade Inc provides extensive services to</h2> */}
              {/* <p>local farmers to mitigate climate impact on coffee production, such as adaptation measures workshops to smallholder coffee farmers through partnership with local civil society organizations.
              </p> */}
            </div>

            {/* <i class="fa-solid fa-utensils"></i>
            <i class="fa-regular fa-hand-pointer"></i>
            <i class="fa-solid fa-truck-fast"></i> */}

            <div className="prod-container__boxes">
              {/* <div className="prod-container__boxes__box">
              
                <img src={SelectCar} alt="icon_img" />
                <h3>Coffee Estate</h3>
                <p>
                Established Coffee Estate 

                </p>
              </div> */}

              <div className="prod-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>Arabica</h3>
                <p>
                Arabica coffee considered a delicate coffee with lower caffeine. It has a distinctive strong taste and smell characteristic.
                </p>
              </div>

              <div className="prod-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Robusta</h3>
                <p>
                Robusta coffee is notoriously bitter and is used primarily in instant coffee, espresso, and as a filler in certain blends of ground coffee
                </p>
              </div>



              <div className="prod-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Yirgacheffe</h3>
                <p>
                Yirgacheffe coffees deliver a palate of diversity. The flavors of the natural lean towards chocolate, citrus, and berry.
                </p>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Prod;
