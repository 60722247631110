import { useEffect, useState } from "react";


function Footer() {


  const privacy = `<h2>Salco Trade - Privacy Policy </h2>
  <strong>1. Introduction</strong>
  Welcome to Salco Trade! This Privacy Policy outlines how we collect, use, and protect your
  personal information when you visit our website and make purchases. By using our services,
  you agree to the practices described in this policy.
  <strong>2. Information We Collect</strong>
  2.1. Personal Information: When you create an account or place an order, we collect
  information such as your name, email address, shipping address, and payment details.
  2.2. Usage Data: We gather data on how you interact with our website, including pages visited,
  links clicked, and products viewed.
  <strong>3. How We Use Your Information</strong>
  3.1. We use your personal information to process orders, fulfill requests, and provide customer
  support.</br>
  3.2. Your email address may be used to send you updates about your order, promotions, and
  newsletters. You can opt-out of these communications.
  <strong>4. Data Security</strong>
  4.1. We employ industry-standard security measures to safeguard your information from
  unauthorized access or disclosure.</br>
  4.2. While we strive to protect your data, no method of transmission over the internet or
  electronic storage is completely secure.

  <strong>5. Sharing Your Information</strong>
  5.1. We may share your information with trusted third-party service providers to assist with
  order processing, payment processing, and shipping.</br>
  5.2. Your information may be disclosed if required by law or to protect our rights, safety, or
  property.
  <strong>6. Cookies and Tracking Technologies</strong>

  
  6.1. We use cookies and similar technologies to enhance your experience on our website.</br>
  6.2. You can adjust your browser settings to refuse cookies or be alerted when cookies are
  being sent.

  <strong>7. Your Choices</strong>
  
  7.1. You can update your account information and preferences at any time.</br>
  7.2. You may request the deletion of your account and associated data by contacting us.

  <strong>8. Children&#39;s Privacy</strong>
  8.1. Our services are not intended for users under the age of 12. We do not knowingly collect
  personal information from children.

  <strong>9. Changes to Privacy Policy</strong>
  9.1. We may update this Privacy Policy to reflect changes in our practices or legal requirements.
  The revised policy will be posted on our website.

  <strong>10. Contact Us</strong>
  10.1. If you have questions about this Privacy Policy, need assistance, or want to exercise your
  rights, please contact us.`;


  const termscond = `<h2>Salco Trade - Coffee Bean Sales Terms and Conditions</h2>
  <strong>1. Acceptance of Terms</strong>
  Welcome to Salco Trade! By using our website, you agree to comply with the following
  terms and conditions. If you do not agree, please refrain from using our services.
  
  <strong>2. Coffee Bean Sales</strong>
  2.1. Salco Trade offers an online platform for the sale and purchase of coffee beans.</br>
  2.2. All orders are subject to availability and acceptance by Salco Trade.
  
  <strong>3. Shipping and Delivery</strong>
  
  3.1. We aim to process and ship orders promptly, but delivery times may vary.</br>
  3.2. Shipping costs and estimated delivery times are detailed during checkout.</br>
  3.3. Salco Trade is not responsible for delays caused by shipping carriers or customs
  procedures.

  <strong>4. Returns and Refunds</strong>
  4.1. We strive to deliver quality products. If you receive damaged or incorrect items, please
  contact us within 3 days of receipt.</br>
  4.2. Refunds or replacements will be provided for valid claims following our Return Policy.
  
  <strong>5. Privacy and Security</strong>
  5.1. We collect and process user data as outlined in our Privacy Policy.</br>
  5.2. Payment information is securely processed and not stored on our servers.
  
  <strong>6. User Conduct</strong>
  6.1. Users must provide accurate and complete information during the order process.</br>
  6.2. Users must not use our platform for any illegal or unauthorized purposes.
  
  <strong>7. Disclaimer of Warranties</strong>
  7.1. Salco Trade makes no warranties or representations about the quality, accuracy, or
  reliability of the coffee beans sold on our platform.</br>
  7.2. Coffee bean quality may vary due to factors such as origin, roasting, and brewing
  methods.

  <strong>8. Limitation of Liability</strong>
  8.1. Salco Trade shall not be liable for any direct, indirect, or consequential damages arising
  from the purchase or use of our coffee beans.</br>
  8.2. Some jurisdictions do not allow the limitation or exclusion of liability. In such cases, our
  liability is limited to the extent permitted by law.

  <strong>9. Termination</strong>
  9.1. Salco Trade reserves the right to cancel orders or suspend accounts for violations of
  these terms.</br>
  9.2. Users may terminate their account at any time.

  <strong>10. Changes to Terms</strong>
  10.1. Salco Trade may update these terms and conditions as needed. Users will be notified of
  changes.</br>
  10.2. Continued use of our services after changes indicates acceptance of the revised terms.
  
  <strong>11. Governing Law</strong>
  11.1. These terms and conditions are governed by the laws of Ontario. Disputes will be
  resolved in the courts of Ontario.
 
  <strong>12. Contact Us</strong>
  12.1. If you have questions or concerns about these terms, please reach out to us.`;



  
  const [goUp, setGoUp] = useState(false);
  const [terms, setTerms] = useState(termscond);


  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 200) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);


  const openModCon = (content) => {
    // e.preventDefault();
    setTerms(content);
    openModal();
  }

  const [modal, setModal] = useState(false); //  class - active-modal
  const openModal = () => {
    setModal(!modal);
    const modalDiv = document.querySelector(".mod-modal");
    modalDiv.scroll(0, 0);
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);





  return (
    <>
      <footer>
                {/* Modal overlay */}
                <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>
        <div className="container">
          <div className="footer-content">
            <div className="footer-content__1">
                 &copy; 2023 Salco
            </div>

            <div className="footer-content__2">
              <a onClick={() => openModCon(termscond)}>Terms and Conditions</a>&nbsp;|&nbsp;<a  onClick={() => openModCon(privacy)}>Privacy Policy</a>                
            </div>
          </div>

          <div className="footer-content-3">
          </div>
        </div>



          {/* page up */}

          <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
          >
            <i className="fa-solid fa-angle-up"></i>
          </div>

          
      </footer>

        {/* modal ------------------------------------ */}
        <div className={`mod-modal ${modal ? "active-modal" : ""}`}>
       
       {/* title*/}
       <div className="mod-modal__title">
         <i onClick={openModal} className="fa-solid fa-xmark"></i>
       </div> 

       {/* message */}
       <div className="mod-modal__message" dangerouslySetInnerHTML={{__html: terms}}>
        
       </div>
      
     </div>



    </>
  );
}

export default Footer;
