
function Contact() {
  return (
    <>
    <section className="contact-page" id="contactus-sec">
      <div className="container">
        <div className="contact-div">
          <div className="contact-div__text">
            <h2>Need additional information?</h2>
            <p>
              TALK TO US FILLING IN THE FORM
            </p>
            <a href="/">
              <i className="fa-solid fa-phone"></i>&nbsp; 00905316751579
            </a>
            <a href="/">
              <i className="fa-solid fa-envelope"></i>&nbsp;
              trsalco@gmail.com
            </a>
            <a href="/">
              <i className="fa-solid fa-location-dot"></i>&nbsp; Şirketi GÜVENEVLER Mah. 1928 Sok.
EKİNCİ GLOBAL İŞ MERKEZİ No: 5 / 25 YENİŞEHİR MERSİN/Türkiye
            </a>
          </div>
          <div className="contact-div__form">
            <form>
              <label>
                Full Name <b>*</b>
              </label>
              <input type="text" placeholder='E.g: "Joe Shmoe"'></input>

              <label>
                Email <b>*</b>
              </label>
              <input type="email" placeholder="youremail@example.com"></input>

              <label>
                Tell us about it <b>*</b>
              </label>
              <textarea placeholder="Write Here.."></textarea>

              <button type="submit">
                <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Send
                Message
              </button>
            </form>
          </div>
        </div>
      </div>
  
    </section>
  </>
  );
}

export default Contact;
